<template>
  <div class="login">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Add Marketplace Item</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <form action="" @submit.prevent="submit" class="box">
            <div>
              <label for="" class="label">Photo Dropzone</label>

              <div v-bind="getRootProps()">
                <input v-bind="getInputProps()" />
                <p v-if="isDragActive">Drop the files here ...</p>
                <p v-else>Drag and drop some files here, or click to select files</p>
              </div>
              <button class="button is-success" @click="open">Open</button>
            </div>
            <br/>
            <div class="field">
              <label for="" class="label">Name of the Listing</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="70 Spisz Dresses"
                    class="input"
                    v-model="name"
                    autofocus
                    required
                    id="name"
                    name="name"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Description</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="Made in the 1980s..."
                    class="input"
                    v-model="description"
                    required
                    id="description"
                    name="description"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Asking Price</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="$180.35"
                    class="input"
                    v-model="askingPrice"
                    required
                    id="askingPrice"
                    name="askingPrice"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Contact Person</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="John Smith"
                    class="input"
                    v-model="contactPerson"
                    required
                    id="contactPerson"
                    name="contactPerson"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Phone Number</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="(123) 456-7890"
                    class="input"
                    v-model="phoneNumber"
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Location</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="Washington, D.C."
                    class="input"
                    v-model="location"
                    required
                    id="location"
                    name="location"
                >
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Quantity</label>
              <div class="control">
                <input
                    type="text"
                    placeholder="10"
                    class="input"
                    v-model="quantity"
                    required
                    id="quantity"
                    name="quantity"
                >
              </div>
            </div>
            <div class="field">
              <button type="submit" class="button is-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import {useUserStore} from "../store";
import { useDropzone } from "vue3-dropzone";

export default {
  name:"AddMarketplaceItem",
  setup() {
    // eslint-disable-next-line no-unused-vars
    let store = useUserStore()
    // return {store}
    // eslint-disable-next-line no-unused-vars
    const url = "{your_url}"; // Your url on the server side
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        formData.append("images[]", files[x]);
      }

      // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.

    //   axios
    //       .post(url, formData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       })
    //       .then((response) => {
    //         console.info(response.data);
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    };

    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles); // saveFiles as callback
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    submit() {
      firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.store.login(this.email)
            if(this.$route.query.hasOwnProperty("redirect")){
              this.$router.push(this.$route.query.redirect)
            }
            else{
              this.$router.push("/");
            }
          })
          .catch(err => {
            this.$swal({icon: 'error', title: "Oops...", text: err.message});
            this.error = err.message;
          });
    },
  }
};
</script>
<style lang="scss" scoped>
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 40px;
}

.hero.is-primary {
  background-color: transparent;
}
</style>